<script setup lang="ts">
interface IEmits {
    (e: 'update:modelValue', value: any[]): void
}
const { loading, start, stop } = await useLoading()
const applied = ref(true)
const categories = ref([])
const selectedCategories = ref([])
const emit = defineEmits<IEmits>()
const addSelectedCategory = (category: Object) => {
    if (!selectedCategories.value.includes(category)) {
        selectedCategories.value.push(category)
        applied.value = false
    }
}
const removeSelectedCategory = (id: number) => {
    selectedCategories.value = selectedCategories.value.filter(item => item.id !== id)
    applied.value = false
}
start()
const fetchCategories = async () => {
    const { data, execute } = await useFetch('/api/categories', { method: 'GET' })
    await execute()
    stop()
    categories.value = data.value
}
const getCategoriesFiltered = async () => {
    emit('update:modelValue', toRaw(selectedCategories.value))
    applied.value = true
}
const clearFilters = () => {
    selectedCategories.value = []
    getCategoriesFiltered()
}

onMounted(async () => {
    await fetchCategories()
})
</script>
<template>
    <div>
        <aside class="mr-4">
            <div class="rounded-lg">
                <h4 class="text-3xl text-primary-500 p-2">
                    Categorías
                </h4>
                <div v-if="selectedCategories?.length > 0" class="relative">
                    <div class="absolute bg-primary-300/20 -skew-y-3 inset-0 rounded-md p-4">
                    </div>
                    <div class="relative z-20 p-4">
                        <div>
                            <div>
                                <ul
                                    class="flex items-center justify-start p-2 gap-1 flex-wrap relative">
                                    <li v-for="item in selectedCategories" :key="item.id"
                                        class=" z-10 text-xs bg-primary-500 hover:bg-primary-400 w-auto text-white rounded-md flex items-center justify-center relative overflow-hidden duration-700 transition-all">
                                        <span class="py-1 px-2 mr-4">{{ item.name }}</span>
                                        <button @click="removeSelectedCategory(item.id)"
                                            class="hover:bg-primary-300 w-5 shrink-0 absolute right-0 inset-y-0 flex items-center justify-center duration-700 transition-all">
                                            <Icon name="heroicons:x-mark-20-solid"
                                                class="h-3 w-3 xs:flex-none" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-center my-4 mx-4 flex items-center justify-between">
                            <button @click="clearFilters"
                                v-if="selectedCategories?.length > 0"
                                class="text-xs underline text-primary-500">
                                Borrar filtros
                            </button>
                            <ButtonBase v-if="!applied" @click="getCategoriesFiltered()">
                                Aplicar filtro
                            </ButtonBase>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="loading">
                    <ul class="ml-2 mt-4">
                        <li v-for="item in [1, 2, 3, 4, 5]" :key="item"
                            class="flex flex-col mb-4 gap-4 rounded-md">
                            <SkeletonLoader class="w-20 h-6" />
                            <SkeletonLoader class="w-36 h-6" />
                            <SkeletonLoader class="w-28 h-6" />
                        </li>
                    </ul>
                </div>
                <div v-else v-for="category in categories"
                    @click="addSelectedCategory(category)" :key="category.id">
                    <!--                <Icon name="material-symbols:arrow-circle-right-outline" class="h5 w-5" />-->
                    <div v-if="!selectedCategories.includes(category)"
                        class="text-slate-600 hover:text-primary-500 hover:underline mx-2 my-4 flex items-center cursor-pointer ml-4 transition-all duration-200">
                        {{ category.name }}
                    </div>
                </div>
            </div>

        </aside>

    </div>
</template>
